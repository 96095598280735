.form {
  --black: #000000;
  --ch-black: #141414;
  --eer-black: #1b1b1b;
  --night-rider: #2e2e2e;
  --white: #ffffff;
  --af-white: #f3f3f3;
  --ch-white: #e1e1e1;
  --tomato: #a80303;
  font-family: Helvetica, sans-serif;
  border: 2px solid var(--glow-color);
  padding: 2rem;
  display: flex;
  max-width: 70vw;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  color: var(--night-rider);
  background-color: var(--white);
  border-radius: 8px;
  position: relative;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.15);
}

/*----heading and description-----*/

.info {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.title2 {
  font-size: 1.5rem;
  font-weight: 900;
}
@media (max-width: 600px) {
  .title2 {
    font-size: 5vw;
  }

  .description {
    font-size: 3vw;
  }
}

.description {
  margin-top: 10px;
  font-size: 1rem;
}

/*----input-fields------*/

.form .input-fields {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 2rem;
}

/*-----verify and clear buttons-----*/

.action-btns {
  display: flex;
  margin-top: 20px;
  gap: 0.5rem;
}

.verify {
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 500;
  color: var(--white);
  text-shadow: none;
  background: #c5941b;
  box-shadow: transparent;
  /* border: 1px solid var(--night-rider); */
  transition: 0.5s ease;
  user-select: none;
  cursor: pointer;
}

.verify:hover,
.verify:focus {
  /* color: var(--white); */
  transform: scale(1.06);
  transition: transform 0.3s, border-width 0.3s;
}

.clear {
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 500;
  color: var(--night-rider);
  text-shadow: none;
  background: transparent;
  border: 1px solid var(--night-rider);
  transition: 0.5s ease;
  user-select: none;
  cursor: pointer;
}

.clear:hover,
.clear:focus {
  color: var(--tomato);
  background-color: var(--white);
  border: 1px solid var(--tomato);
  transform: scale(1.06);
  transition: transform 0.3s, border-width 0.3s;
}

/*-----close button------*/

.close {
  position: absolute;
  right: 10px;
  top: 10px;
  background-color: var(--ch-white);
  color: var(--af-white);
  height: 30px;
  width: 30px;
  display: grid;
  place-items: center;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 600;
  transition: 0.5s ease;
}

.close:hover {
  background-color: var(--tomato);
  color: var(--white);
}
.loader2 {
  box-sizing: border-box;
  display: inline-block;
  width: 50px;
  height: 80px;
  border-top: 5px solid black;
  border-bottom: 5px solid black;
  position: relative;
  background: linear-gradient(#c5941b 30px, transparent 0) no-repeat;
  background-size: 2px 40px;
  background-position: 50% 0px;
  animation: spinx 5s linear infinite;
}

.loader2:before,
.loader2:after {
  content: "";
  width: 40px;
  left: 50%;
  height: 35px;
  position: absolute;
  top: 0;
  transform: translatex(-50%);
  background: rgba(185, 184, 184, 0.4);
  border-radius: 0 0 20px 20px;
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: 0 0px;
  animation: lqt 5s linear infinite;
}

.loader2:after {
  top: auto;
  bottom: 0;
  border-radius: 20px 20px 0 0;
  animation: lqb 5s linear infinite;
}

@keyframes lqt {
  0%,
  100% {
    background-image: linear-gradient(#c5941b 40px, transparent 0);
    background-position: 0% 0px;
  }

  50% {
    background-image: linear-gradient(#c5941b 40px, transparent 0);
    background-position: 0% 40px;
  }

  50.1% {
    background-image: linear-gradient(#c5941b 40px, transparent 0);
    background-position: 0% -40px;
  }
}

@keyframes lqb {
  0% {
    background-image: linear-gradient(#c5941b 40px, transparent 0);
    background-position: 0 40px;
  }

  100% {
    background-image: linear-gradient(#c5941b 40px, transparent 0);
    background-position: 0 -40px;
  }
}

@keyframes spinx {
  0%,
  49% {
    transform: rotate(0deg);
    background-position: 50% 36px;
  }

  51%,
  98% {
    transform: rotate(180deg);
    background-position: 50% 4px;
  }

  100% {
    transform: rotate(360deg);
    background-position: 50% 36px;
  }
}

.loader-wrapper {
  position: relative;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}

.loader-wrapper .packman::before {
  content: "";
  position: absolute;
  width: 50px;
  height: 25px;
  background-color: #c5941b;
  border-radius: 100px 100px 0 0;
  transform: translate(-50%, -50%);
  animation: pac-top 0.5s linear infinite;
  transform-origin: center bottom;
  z-index: 10;
}

.loader-wrapper .packman::after {
  content: "";
  position: absolute;
  width: 50px;
  height: 25px;
  background-color: #c5941b;
  border-radius: 0 0 100px 100px;
  transform: translate(-50%, 50%);
  animation: pac-bot 0.5s linear infinite;
  transform-origin: center top;
  z-index: 10;
}

@keyframes pac-top {
  0% {
    transform: translate(-50%, -50%) rotate(0);
  }

  50% {
    transform: translate(-50%, -50%) rotate(-30deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(0);
  }
}

@keyframes pac-bot {
  0% {
    transform: translate(-50%, 50%) rotate(0);
  }

  50% {
    transform: translate(-50%, 50%) rotate(30deg);
  }

  100% {
    transform: translate(-50%, 50%) rotate(0);
  }
}

.dots .dot {
  position: absolute;
  z-index: 1;
  top: 8px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #c5941b;
}

.dots .dot:nth-child(1) {
  left: 90px;
  animation: dot-stage1 0.5s infinite;
}

.dots .dot:nth-child(2) {
  left: 60px;
  animation: dot-stage1 0.5s infinite;
}

.dots .dot:nth-child(3) {
  left: 30px;
  animation: dot-stage1 0.5s infinite;
}

.dots .dot:nth-child(4) {
  left: 10px;
  animation: dot-stage2 0.5s infinite;
}

@keyframes dot-stage1 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(-24px, 0);
  }
}

@keyframes dot-stage2 {
  0% {
    transform: scale(1);
  }

  5%,
  100% {
    transform: scale(0);
  }
}
