.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}




@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


.langbtns{
  display: flex;
  flex-direction: row-reverse;
  position:relative;
  top: 0;
  z-index: 4;
}
.langbtns button{
  background-color: #C5941B;
  border: 1px solid #C5941B;
  box-sizing: border-box;
  color: white !important;
  cursor: pointer;
  display: inline-block;
  font-family: "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  list-style: none;
  outline: none;
  text-align: center;
  text-decoration: none;
  transition: color 100ms;
  vertical-align: baseline;
  user-select: none;
  touch-action: manipulation;
}



.langbtns button:hover{
  background-color:transparent;
  color: grey !important;
  transition: .5s ease-in-out;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: "etihadBoldEnglish";
  src: 
  url(./Fonts/Etihad-Fonts/Etihad-Altis-English/Web-Fonts/woff/EtihadAltis-Bold_V3.woff) ,
  url(./Fonts/Etihad-Fonts/Etihad-Altis-English/OTF-format/EtihadAltis-Bold_V3.otf),
  url(./Fonts/Etihad-Fonts/Etihad-Altis-English/Web-Fonts/eot/EtihadAltis-Bold_V3.eot) ,
}
@font-face {
  font-family: "etihadBookEnglish";
  src: 
  url(./Fonts/Etihad-Fonts/Etihad-Altis-English/Web-Fonts/woff/EtihadAltis-Book_V3.woff) ,
  url(./Fonts/Etihad-Fonts/Etihad-Altis-English/OTF-format/EtihadAltis-Book_V3.otf),
  url(./Fonts/Etihad-Fonts/Etihad-Altis-English/Web-Fonts/eot/EtihadAltis-Book_V3.eot) ,
}
@font-face {
  font-family: "etihadMediumEnglish";
  src: 
  url(./Fonts/Etihad-Fonts/Etihad-Altis-English/Web-Fonts/woff/EtihadAltis-Medium_V3.woff) ,
  url(./Fonts/Etihad-Fonts/Etihad-Altis-English/OTF-format/EtihadAltis-Medium_V3.otf),
  url(./Fonts/Etihad-Fonts/Etihad-Altis-English/Web-Fonts/eot/EtihadAltis-Medium_V3.eot) ,
}

@font-face {
  font-family: "etihadBoldArabic";
  src: 
  url(./Fonts/Etihad-Fonts/Etihad-Arabic/Web-Fonts/woff/EtihadArabicW05-Bold.woff) ,
  url(./Fonts/Etihad-Fonts/Etihad-Arabic/OTF/EtihadArabic-Bold.otf),
  url(./Fonts/Etihad-Fonts/Etihad-Arabic/Web-Fonts/woff2/EtihadArabicW05-Bold.woff2) ,
}
@font-face {
  font-family: "etihadBookArabic";
  src: 
  url(./Fonts/Etihad-Fonts/Etihad-Arabic/Web-Fonts/woff/EtihadArabicW05-Book.woff) ,
  url(./Fonts/Etihad-Fonts/Etihad-Arabic/OTF/EtihadArabic-Book.otf),
  url(./Fonts/Etihad-Fonts/Etihad-Arabic/Web-Fonts/woff2/EtihadArabicW05-Book.woff2) ,
}
@font-face {
  font-family: "etihadMediumArabic";
  src: 
  url(./Fonts/Etihad-Fonts/Etihad-Arabic/Web-Fonts/woff/EtihadArabicW05-Medium.woff) ,
  url(./Fonts/Etihad-Fonts/Etihad-Arabic/OTF/EtihadArabic-Medium.otf),
  url(./Fonts/Etihad-Fonts/Etihad-Arabic/Web-Fonts/woff2/EtihadArabicW05-Medium.woff2) ,
}
