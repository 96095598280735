.OrderHome {
  border-radius:10px;
  border: 2px solid #cb9800;
  padding: .5rem;
  background: rgba(243.24, 239.92, 160.20, 0.07);
  margin-bottom: .5rem;
}

.OrderHomeHead {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.OrderHomeHead p {
  color: #000;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.88px;
}

.OrderHomeTrackBtn {
  color: #fff;
  text-align: center;
  letter-spacing: 0.6px;
  background-color: #cb9800;
  border: none;
  border-radius: 5px;
  padding: 0.3rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.OrderHomeTrackBtn:hover {
  border: 2px solid #cb9800;
  color: #cb9800;
  background-color: #fff;
  cursor: pointer;
}

.OrderHomeFooter {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  /* margin-bottom: 1rem; */
  font-size: small;
  /* margin: 0.5rem; */
}

/* .OrderHomeItems {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 0.8rem;
  overflow: auto;

} */

.OrderHomeItemsWrapper {
    display: flex;
    gap: 0.8rem;
    padding-bottom: 0.2rem;
    overflow-y: scroll; /* Enable horizontal scrolling */
    flex-direction: row;
    flex-wrap: wrap; /* Prevent items from wrapping to the next line */
    max-width: 100%; /* Ensure the container doesn't exceed its parent's width */
    /* background-color: blue; */
    /* overflow: auto; */
    /* overflow-y: hidden; */
  }
  /* .OrderHomeItemsWrapper::-webkit-scrollbar {
    width: 0.1rem;
  }
  
  .OrderHomeItemsWrapper::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
   */
.qrScanBody{
  display: flex;
  height: 45%;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  margin-top: 4rem;

}

.QRSpan{
  color: #000;
  text-align: center;
  /* font-family: Inter; */
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.QrParagraph{
  color: #B9B9B9;
  text-align: center;
  /* font-family: Inter; */
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}