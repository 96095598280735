.LanguagesContainer{
    flex-direction: column;
    align-items: center;
    background-color: #fafafa;
    min-height: 100vh;
    display: flex;
    max-width: 100%;
    overflow: hidden;
}


 .ltr {
    direction: ltr;
  }
  
 .rtl {
    direction: rtl;
    text-align: right;
  }

.Languages{
    /* flex-direction: column; */
    align-items: center;
    /* background-color: #fafafa; */
    /* min-height: 50vh; */
    display: flex;
    max-width: 100%;
    overflow: hidden;
    margin-top: 1rem;
}
