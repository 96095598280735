.OrdersContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  /* background-color: #fafafa; */
  min-height: 100vh;
  overflow: hidden;
}
.OrdersContainerLoading{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #fafafa;
  min-height: 100vh;
  overflow: hidden;
}
.AllOrders {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* margin-top: 2rem; */
  max-width: 90%;
  flex-wrap: wrap;
}
.OrderCard {
  min-width: 80vw;
  max-width: 80vw;
  display: flex;
  align-items: center;
  border-radius: 10px;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
  border: 1px solid #dfe1e5;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.09);
  text-decoration: none;
  color: inherit;
  flex-direction: column;
  margin-bottom: 1rem;
  margin: 1rem;
}
.OrderCard:hover {
  border-radius: 10px;
  transform: scale(1.03);
  background-color: #f0f0f0;
}
@media (max-width: 600px) {
  .OrderCard {
    min-width: 90vw;
    max-width: 90vw;
    margin: 0rem;
    margin-bottom: 1rem;
  }
}
.ImageInfo {
  /* flex: 1; */
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
  width: 100%;
  padding: 1rem;
  background-color: red;
}
.OrderCardImage {
  /* flex: 1; */
  display: flex;
  height: 15vh;
  justify-content: center;
  align-items: center;
}


.OrderCardImage img {
  width: 60%;
  height: 100%;
  object-fit: cover; 
  border-radius : 1rem;
}
@media (max-width: 600px) {
  .OrderCardImage img {
    width: 90%;
    height: 60%;
    object-fit: contain; 
  }
}

.MuiTabs-indicator {
  background-color: #c5941b !important; /* Change the indicator (underline) color */
}

/* Style for unselected tabs */
.MuiTab-textColorInherit:not(.Mui-selected) {
  color: #c5941b !important; /* Change the text color of unselected tabs */
  border-bottom: 2px solid transparent !important; /* Remove the default bottom border */
}

/* Style for selected tab */
.MuiTab-textColorInherit.Mui-selected {
  color: #c5941b !important; /* Change the text color of the selected tab */
  border-bottom: 2px solid #c5941b !important; /* Add a border to the selected tab */
}
