/* NavBar.css */

.NavBarContainer2 {
  display: flex;
  justify-content:center;
  align-items: center;
  background-color: #fafafa;
  overflow: hidden;
  padding: .5rem;
}

.DeliverySection {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  transition: transform 0.6s ease-in-out;
  display: flex;
  flex-direction: column;
  z-index: 3;
}
.PopUpHeader {
  flex: 1;
  justify-content: center;
  align-items: center;
}
.OrderTypes {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  /* padding-left: 1rem; */
}

.OrderTypeItem {
  padding: 1.5rem;
  font-weight: bold;
  cursor: pointer;
  width: 100vw;
  text-align: left;
  flex-direction: row;
  display: flex;
}
.OrderTypes :hover {
  background-color: #ecebeb;
}

.OrderTypeItem.active {
  background-color: #0ca1d4;
  color: white;
}

.OrderTypeLine {
  width: 90vw;
  height: 2px;
  background-color: gray;
  opacity: 0.3;
  margin-left: 1rem;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
}

.OrderTypeIcon {
  margin-right: 10px; /* Add some spacing between icon and text */
  color: #464545;
}
.NavDrawer {
  width: 300px;
}

.NavDrawerOpen {
  transform: translateX(0);
}

/* Sidebar container */
.NavBarSidebar {
  position: fixed;
  top: 0;
  left: -1000px; /* Initial position outside the screen */
  width: 60vw;
  height: 100%;
  background-color: #ffffff;
  transition: left 0.5s ease-in-out;
  z-index: 4;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.NavBarSidebar.open {
  left: 0; /* Display the sidebar */
}
@media (width>600px) {
  .NavBarSidebar {
    width: 30vw;
  }
}
.NavExtension {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.7rem;
  margin-top: 0.5rem;
  max-width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  /* background-color: red; */
}
.CallWaiterContainer {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.3rem;
}
.CallWaiterbtn {
  flex: 1;
  display: flex;
  width: 30%; /* Adjust the width as needed */
  height: 70%; /* Adjust the height as needed */
  padding: 0.5rem; /* Adjust the padding as needed */
  /* background-color: #eaeae8; */
  border-radius: 10px;
  border: 1px solid #cb9800;
  background-color: #c5951bdd;
  color: rgba(255, 255, 255, 0.868);
  /* color: #757574; */
  flex-direction: column;
  cursor: pointer;
  justify-content: center;
  align-items: center;
}

/* .CallWaiterbtn:disabled{
  color: #757574;
  border: 1px solid #cb9800;
  cursor: auto;
  background-color: #eaeae8 ;
} */

/* .CallWaiterbtn:hover{
  border: 1px solid #cb9800;
  background-color: transparent;
  color: #cb9800;
} */

.CallWaiterbtn__Disabled{
  color: #757574;
  border: 1px solid #cb9800;
  cursor: auto;
  background-color: #eaeae8 ;
  flex: 1;
  display: flex;
  width: 30%; /* Adjust the width as needed */
  height: 70%; /* Adjust the height as needed */
  padding: 0.5rem; /* Adjust the padding as needed */
  /* background-color: #eaeae8; */
  border-radius: 10px;
  /* color: #757574; */
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

}
