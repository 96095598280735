/* ItemCard.css */
.ItemCardContainer {
  /* min-width: 22rem;
  max-width: 24rem; */
  display: flex;
  align-items: flex-start;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
  text-decoration: none;
  color: inherit;
  margin: 0.35rem;
  width: 100%;
  border-radius: 5px;
  background: #FFF;
  box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.12);
  
}

.ItemCardContainer:hover {
  border-radius: 10px;
  transform: scale(1.03);
  background-color: #f0f0f0;
}

.CardInfo {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  padding: 0.7rem;
  text-align: start;
  width: 90%;
  height: 100%;
  overflow: hidden;
}

.CardImage {
  width: 100%; /* Set a fixed width */
  height: 50%; /* Set a fixed height */
  /* display: flex; */
  flex: 1;
  justify-content: center;
  align-items: center;
  /* padding: 0.2rem; */
}

.CardImage img {
  width: 100%;
  height: 30vh;
  object-fit: cover;
  border-radius: 10px;
}

@media (max-width: 600px) {
  /* .ItemCardContainer {
    min-width: 44vw;
    max-width: 44.5vw;
    height: auto;
  }
  .CardImage img {
    height: 20vh;
  } */
}

@media (max-width: 770px ) and (min-width: 610px){
  .ItemCardContainer {
    min-width: 22rem;
    max-width: 21.9rem;
    height: auto;
  }
}

.OutOfStock {
  position: relative;
  transform: translate(-25%, -20%);
  background-color: #fcc6c2;
  padding: 5px 10px;
  color: #f44b3e;
  border-radius: 5px;
  font-weight: 500;
}

.Description {
  margin-top: 0.3rem;
  flex: 1;
  color: #919191;
  font-size: 20;
  /* font-family: 'etihadBook'; */
  font-weight: 400;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Limit to 2 lines */
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  overflow: hidden;
}
.horizontal-line {
  border-top: 1px solid #D9D9D9; /* Adjust the style and color as needed */
  margin: 5px 0; /* Adjust the margin for spacing above and below the line */
}
.choices {
  color: 'black';
  /* font-family: 'etihadBook'; */
  font-weight: '400';
  margin-left: .3rem;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* Limit to 2 lines */
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  overflow: hidden;
}
