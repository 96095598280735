.Description {
    margin-top: 0.3rem;
    flex: 1;
    color: #919191;
    font-size: 20;
    font-family: 'etihadBook';
    font-weight: 400;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Limit to 2 lines */
    -webkit-box-orient: vertical;
    word-wrap: break-word;
    overflow: hidden;
  }
  .items {
    color: 'black';
    /* font-family: 'etihadBook'; */
    font-weight: '400';
    margin-left: .2rem;
    margin-right: .2rem;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* Limit to 2 lines */
    -webkit-box-orient: vertical;
    word-wrap: break-word;
    overflow: hidden;
    align-content: center;
    align-items: center;
  }
  