.form {
  --black: #000000;
  --ch-black: #141414;
  --eer-black: #1b1b1b;
  --night-rider: #2e2e2e;
  --white: #ffffff;
  --af-white: #f3f3f3;
  --ch-white: #e1e1e1;
  --tomato: #a80303;
  font-family: Helvetica, sans-serif;
  border: 2px solid var(--glow-color);
  padding: 2rem;
  display: flex;
  max-width: 70vw;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  color: var(--night-rider);
  background-color: var(--white);
  border-radius: 8px;
  position: relative;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.15);
}

/*----heading and description-----*/

.info {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.title2 {
  font-size: 1.5rem;
  font-weight: 900;
}
@media (max-width: 600px) {
  .title2 {
    font-size: 5vw;
  }
  
.description {
    font-size: 3vw;
  }
}

.description {
  margin-top: 10px;
  font-size: 1rem;
}

/*----input-fields------*/

.form .input-fields {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 2rem;
}

.form .review{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  width: 90%;
  margin-bottom: 3rem;
}

/*-----verify and clear buttons-----*/

.action-btns {
  display: flex;
  margin-top: 0rem;
  gap: 0.5rem;
}

.verify {
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 500;
  color: var(--white);
  text-shadow: none;
  background: #c5941b;
  box-shadow: transparent;
  /* border: 1px solid var(--night-rider); */
  transition: 0.5s ease;
  user-select: none;
  cursor: pointer;
}

.verify:hover,
.verify:focus {
  /* color: var(--white); */
  transform: scale(1.06);
  transition: transform 0.3s, border-width 0.3s;
}

.clear {
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 500;
  color: var(--night-rider);
  text-shadow: none;
  background: transparent;
  border: 1px solid var(--night-rider);
  transition: 0.5s ease;
  user-select: none;
  cursor: pointer;
}

.clear:hover,
.clear:focus {
  color: var(--tomato);
  background-color: var(--white);
  border: 1px solid var(--tomato);
  transform: scale(1.06);
  transition: transform 0.3s, border-width 0.3s;
}

/*-----close button------*/

.close {
  position: absolute;
  right: 10px;
  top: 10px;
  background-color: var(--ch-white);
  color: var(--af-white);
  height: 30px;
  width: 30px;
  display: grid;
  place-items: center;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 600;
  transition: 0.5s ease;
}

.close:hover {
  background-color: var(--tomato);
  color: var(--white);
}
