.ReviewBody{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 90vh;
    justify-content: space-between;
}
.Headline{
    margin-top: 2rem;
    font-size: 1.2rem;
    text-transform: uppercase;
    color: #c5941b;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ReviewForm {
    --black: #000000;
    --ch-black: #141414;
    --eer-black: #1b1b1b;
    --night-rider: #2e2e2e;
    --white: #ffffff;
    --af-white: #f3f3f3;
    --ch-white: #e1e1e1;
    --tomato: #a80303;
    font-family: Helvetica, sans-serif;
    border: 2px solid var(--glow-color);
    padding: 2rem;
    display: flex;
    max-width: 70vw;
    height: 100vh;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    color: var(--night-rider);
    background-color: var(--white);
    border-radius: 8px;
    position: relative;
    justify-content: space-between;
  }
  

  