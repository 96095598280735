.HeaderContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    padding: 1rem;
    overflow: hidden;
    height: 7vh;
    margin-bottom: 2rem;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2); /* Add box shadow */
    background-color: #C5941B;
}
