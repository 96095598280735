/* Categories.css */
.CategoriesContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  margin-top: 0.3rem;
}

.CategoriesWrapper {
  display: flex;
  gap: 1rem;
  overflow-x: auto;
  padding-bottom: 0.5rem;
  /* Hide scrollbar for Webkit browsers */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}
.CategoriesWrapper::-webkit-scrollbar {
  width: 0.1rem;
}

.CategoriesWrapper::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.CategoryItem {
  cursor: pointer;
  padding: 0.5rem 1rem;
  color: black;
  border-radius: 0.5rem;
  white-space: nowrap;
  font-weight: 500;
  text-transform: capitalize;
}
.CategoryItem:hover {
  font-weight: bolder;
}
.CategoryItem.selected {
  font-weight: bold;
  background-color: #edcc76;
  border-radius: 20px;
  font-size: 18px;
  /* opacity: 0.29; */
}
.CategoryItem.selected__subCategory {
  font-weight: bold;
  background-color: #eee6d2;
  border-radius: 20px;
  font-size: 18px;
  /* opacity: 0.29; */
}
