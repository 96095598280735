/* Home.css */

.Container {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  background-color: #fafafa;
  min-height: 100vh;
  justify-content: flex-start;
  padding: .5rem;
}
.centerLoading {
  justify-content: center;
}
.ContainerSearch {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-color: #fafafa; */
  min-height: 30vh;
  width: 100%;
}
.FixedContainer {
  position: relative;
  /* top: 0; */
  z-index: 2;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
}

.NavBarContainer {
  position: fixed;
  top: 0;
  z-index: 3;
  background-color: #fafafa;
  width: 100%;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
}

.SearchContainer {
  justify-content: center;
  align-items: center;
  margin-top: .5rem;
  margin-bottom: .5rem;
  padding-top: 0rem;
}

@media (max-width: 420px) {
  .SearchContainer {
    margin-top: .5rem;
  }
  .noMarginTop {
    margin-top: 0rem;
  }
}

.Categories {
  /* border: 1px solid rgb(201, 199, 199); */
  border-radius: 5px;
  justify-content: flex-start;
  align-items: flex-start;
  position: sticky;
  position: -webkit-sticky;
  top: 4.5rem; 
  z-index: 2;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: #fafafa;
  max-height: 3rem;
}
.CategoriesHigher {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 1rem;
}
.AllSections {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  margin-bottom: 5rem;
  width: 100%;
  flex-direction: column;
}

.AllSectionsImage {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 0rem;
}

.CartDisplay {
  position: fixed;
  bottom: -1px;
  left: 0;
  width: 100%;
  background: linear-gradient(270deg, #AF8E4B 0%, #D2B56F 100%);
  display: flex;
  height: 10vh;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.28);
  flex-direction: row;
  border-radius: 5px;
  cursor: pointer;
  z-index: 5;
}
.CartDisplay:hover {
  transform: scale(1.01);
  transition: transform 0.3s, border-width 0.3s;
}
.CartDisplayInfo {
  display: flex;
  padding: 1.5rem;
  flex-direction: row;
  width: 100%;
  color: white;
  justify-content: center;
  align-items: center;
}
.CartDisplayempty {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 99vw;
  background: linear-gradient(270deg, #AF8E4B 0%, #D2B56F 100%);
  display: flex;
  height: 10vh;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.28);
  flex-direction: row;
  cursor: not-allowed;
}
.article {
  margin-top: .5rem;
  height: 100%;
  position: relative;
  overflow: hidden;
  border-radius: "10px";
}



.image-container {
  position: relative;
  display: inline-block; /* Ensures the container wraps its content */
}

.background-image {
  width: 100%; /* Make sure the image covers the container */
  height: auto; /* Maintain the image's aspect ratio */
}

.overlay-text {
  position: absolute;
  top: 50%; /* Adjust the top position as needed */
  left: 50%; /* Adjust the left position as needed */
  transform: translate(-50%, -50%); /* Center the button within the container */
  width: 100%;
  font-size: 20px;
  color: white;
  font-weight: 600;
  background: transparent; /* Make the button background transparent */
  border: none; /* Remove button border */
  cursor: pointer; /* Change the cursor to a pointer on hover */
  /* Additional button styles can be added as desired */
}