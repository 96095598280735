.CartContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* background-color: #fafafa; */
  min-height: 90vh;
  overflow: hidden;
  margin-top: 5rem;
}
.Items {
  flex: 1;
  flex-direction: row;
  display: flex;
  width: 100%;
  /* max-height: 20%; */
  justify-content: space-between;
  align-items: center;
}
.ItemInfoImage {
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ItemInfoImage img{
  object-fit: contain;
}
.ItemInfo {
  /* flex: 1; */
  flex-direction: row;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: .5rem;
  margin-bottom: .5rem;
  width: 100%;
}

.ItemImage {
  /* width: 20rem; */
  height: 8rem;
  border-radius: 10px;
  object-fit: cover;
}
.Information {
  padding: .5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin-left: 0.5rem;
  border-radius: 5px;
  background: #FFF;
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.10);
}
.ItemPrice {
  color: gray;
  /* margin-bottom: 3.5rem; */
}
.Line {
  border: 1px solid rgb(209, 209, 209);
  width: 90%;
  margin: 1rem auto;
  opacity: 0.8;
}

.TryThis {
  flex: 1;
  width: 90%;
  justify-content: center;
  align-items: flex-end;
}

.PaymentSummary {
  flex: 1;
  display: flex;
  width: 90%;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 6rem;
}

.PaymentSummaryInfo {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  width: 100%;
}

.InfoRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.LeftText {
  /* font-weight: bold; */
  text-align: right;
}
.FixedCart {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  background-color: white;
  display: flex;
  height: 12vh;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.28);
}
.FixedInfo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    height: 80%;
}
.HalfButton{
    width: 100%;
    height: 80%;
    margin: 1rem;
    border-radius: 10px;
    background-color: transparent;
    border-color:#C5941B;
    border-width: 1.5px;
    cursor: pointer;
}

.HalfButton2{
    width: 100%;
    height: 80%;
    margin: 1rem;
    border-radius: 10px;
    background: linear-gradient(270deg, #AF8E4B 0%, #D2B56F 100%);
    border-color:#C5941B;
    cursor: pointer;
}
.HalfButton:hover,.HalfButton2:hover{
  transform: scale(1.01);
  transition: transform 0.3s, border-width 0.3s;
}
.DisabledContainer {
  background: grey;
  cursor: not-allowed;
}
.TryItems {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* width: 100%; */
  flex-direction: row;
  flex-wrap: wrap;
}

.Box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 15px;
  border: 1px solid grey;
  width: 30%;
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 0.5rem;
}
@media (max-width: 768px) {
  /* .TryItems {
    flex-direction: column;
    align-items: stretch;
  } */
  .Box{
    width: 43%;
  }
}

.TryInfo{
  flex-direction: column;
  text-align: left;
  /* flex: 1; */
}
.TryImageContainer{
  /* display: flex; */
  /* flex: 1; */
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.TryImage {
  width: 6rem;
  height: 6rem;
  border-radius: 10px;
  object-fit: cover;
  /* margin-bottom: 1rem;*/
}
.CounterContainerCart {
  display: flex;
  align-items: center;
}

.marginBottom5{
  margin-bottom: 5rem;
}