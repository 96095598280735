/* Guard.css */

/* Add some overall styling */
.guardContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:flex-start;
    height: 100vh;
    background-color: #f5f5f5;
    width: 84%;
    margin: 0 auto;
    border: 1px solid #997408;
    border-radius: 22px;
    padding: 1rem;
  }
  
  .logo {
    width: 150px;
    height: 100px;
    border-radius: 10px;
    cursor: pointer;
  }
  
  .qr-scan-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
  }
  
  .qr-span {
    margin-top: 20px;
    font-size: 1.5rem;
    color: #333;
  }
  
  .qr-paragraph {
    margin-top: 1rem;
    font-size: 1rem;
    color: #555;
  }
  .scanner-container {
    position: relative;
    width: 250px;
    height: 250px;
    background: linear-gradient(270deg, #AF8E4B 0%, #D2B56F 100%);
    border-radius: 8px;
    overflow: hidden;
  }
  
  .scanner-container video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .scanner-container button {
    background: white;
    color:#AF8E4B;
    border-radius: 8px;
    border-color: black;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
    font-weight: 700;
  }
  
  /* Style the scanner frame */
  .scanner-container::before {
    content: "";
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    height: 80%;
    box-sizing: border-box;
    border-radius: 5px;
    animation: scannerFrameAnimation 2s infinite alternate;
  }
  
  