.master-container {
  display: grid;
  grid-template-columns: auto;
  gap: 5px;
  margin-bottom: 2rem;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}

.card {
  width: 70vw;
  background: #ffffff;
  overflow: hidden;
}

.title {
  padding-top: 1rem;
    width: 100%;
    height: 7vh;
    display: grid;
    align-items: center;
    font-weight: 700;
    justify-content: start;
    margin-bottom: 0.5rem;
    flex-direction: column;
    padding-left: .5rem;
    padding-right: .5rem;
  }

/* cart */
.cart {
  border-radius: 19px 19px 7px 7px;
}

.cart .products {
  flex-direction: column;
  padding: 1rem;
  padding-bottom: 0;
  text-align: start;
}

.cart .products .product {
  text-align: start;
  gap: 0rem;
  margin-bottom: 1rem;
  border-bottom: 2px solid #efeff3;
  justify-content: start;
}

@media (max-width: 600px) {
  .card {
    width: 90vw;
  }
}
.cart .products .product span {
  font-size: 16px;
  font-weight: 500;
  color: #2c2c2e;
  display: block;
  margin-bottom: 5px;
  /* width: 30vw; */
}

.cart .products .product p {
  font-size: 13px;
  font-weight: 600;
  /* background-color: blue; */
  width: 30vw;
  margin: 0;
  margin-bottom: 0.8rem;
}

.card .small {
  font-size: 17px;
  font-weight: 500;
  margin: 0 0 auto auto;
}

.checkout .details {
  display: grid;
  grid-template-columns: 1fr 5fr;
  padding: 1rem;
  gap: 0.5rem;
}

.checkout .details span:nth-child(odd) {
  font-size: 14px;
  font-weight: 700;
  color: #707175;
  margin: auto auto auto 0;
  width: 35vw;
  text-align: start;
}

.checkout .details span:nth-child(even) {
  font-size: 15px;
  font-weight: 700;
  color: #47484b;
  margin: auto 0 auto auto;
  text-align: end;
  white-space: pre-wrap; /* Add this line */
  overflow: hidden;
}

.OrderCardInfo {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
}
.ReviewButtonContainer,
.ReorderButtonContainer {
  width: 90%;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  margin-left: auto;
}


.button {
  --color: white;
  padding: 0.3em 0.8em;
  background-color:  #CCA138;
  border-radius: 0.3em;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: 0.5s;
  font-weight: 400;
  font-size: 17px;
  border: 1px solid;
  font-family: inherit;
  /* text-transform: uppercase; */
  color: var(--color);
  /* font-family: "etihadBook"; */
  z-index: 1;
  /* padding: 1rem; */
  /* margin-bottom: 1rem; */
}

.button::before,
.button::after {
  content: "";
  display: block;
  width: 3rem;
  height: 3rem;
  transform: translate(-50%, -50%);
  position: absolute;
  border-radius: 50%;
  z-index: -1;
  background-color: var(--color);
  /* transition: 1.4s ease; */
  transition: transform 0.3s, border-width 0.3s, 1.4s ease;
}

.button::before {
  top: -1em;
  left: -1em;
}

.button::after {
  left: calc(100% + 1em);
  top: calc(100% + 1em);
}

.button:hover::before,
.button:hover::after {
  height: 410px;
  width: 410px;
}

.button:hover {
  color: rgb(31, 27, 4);
}

.button:active {
  filter: brightness(0.8);
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}
.overlay.noBG{
  background-color: transparent;
}