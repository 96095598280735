.MainCategoryContainer {
  /* min-width: 15rem;
  max-width: 15rem;
  height: 19rem; */
  display: flex;
  align-items: center;
  border-radius: 10px;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
  border: 1px solid #dfe1e5;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.09);
  text-decoration: none;
  color: inherit;
  flex-direction: column;
  margin-bottom: 1rem;
  margin: 0.5rem;
}

.MainCategoryImageContainer {
  min-width: 15rem;
  max-width: 15rem;
  height: 19rem;
  display: flex;
  align-items: center;
  border-radius: 10px;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
  border: 1px solid #dfe1e5;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.09);
  text-decoration: none;
  color: inherit;
  flex-direction: column;
  margin-bottom: 1rem;
  margin: 0.5rem;
}

@media (max-width: 600px) {
  .MainCategoryImageContainer {
    min-width: 40vw;
    max-width: 40vw;
    height: 30vh;
  }
}
.MainCategoryContainer:hover {
  border-radius: 10px;
  transform: scale(1.03);
  background-color: #f0f0f0;
}
.MainCategoryImage {
  flex: 3;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.MainImage {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.MainCategoryInfo {
  flex: 1;
  width: 100%;
  font-weight: bold;
}
