.TrackingContainer {
    display: flex;
    flex-direction: column;
    width: 84%;
    margin: 0 auto;
    border: 1px solid #997408;
    border-radius: 22px;
    padding: 1rem;
    align-items: center;
    justify-content:flex-start;
    margin-top: 5rem;
    height: 100vh;
}

.OrderStatus{
    display: flex;
    border-radius: 10px;
    box-shadow: 0px 0px 11px 0px #0000001C;
    margin-top: 2rem;
    align-items: center;
    /* width: 100%; */
    padding: 0.5rem;
    justify-content: space-between;
}

.OrderTrackingHeader{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 2rem;
    width: 95%;
}
.header{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}


.OrderTrackingHeader h2{
    color: #000;
    text-align: center;
    /* font-family: Inter; */
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 2px;
    margin: 0;
    margin-top: 10px;
}

.OrderTrackingItem {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.OrderTrackingItem span {
    text-align: center;
    font-size: 15px;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.04px;

}

.TrackingHeader{
    display: flex;
    justify-content: center;
    /* align-items: center; */
    flex-direction: row;
    /* width: 100%; */
    overflow: hidden;
}

@media (min-Width: 700px) {
   
}

.cancelButton {
    padding: 0.3em 0.8em;
    background-color: transparent;
    border-radius: 0.3em;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    transition: 0.5s;
    font-weight: 400;
    font-size: 17px;
    border: 1px solid;
    font-family: inherit;
    /* text-transform: uppercase; */
    z-index: 1;
    /* padding: 1rem; */
    /* margin-bottom: 1rem; */
  }
  
 