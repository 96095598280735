.Open {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
}

/* Sidebar container */
.SubCategoriesView {
  width: 95%;
  align-items: center;
  justify-content: center;
  z-index: 4;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: auto; /* Enable vertical and/or horizontal scrolling */
  max-height: 60vh; /* Set a maximum height to limit the scrollable area */
}

.SubCategoryContent {
  /* Styles for the content container */
  padding: 5px; /* Add padding for spacing */
}
