/* Section.css */
.SectionContainer {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* max-width: 100vw; */
  min-height: 10vh;
  flex-direction: column;
  overflow: hidden;
}

.SectionName {
  align-self: flex-start;
  padding-left: 1rem;
  padding-right: 1rem;
  font-weight: bold;
  text-transform: capitalize;
  font-size: 1.1rem;
  color: #000;
  /* text-align: center; */
  /* font-family: Inter; */
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

/* === removing default button style ===*/
.button2 {
  margin: 0;
  height: auto;
  background: transparent;
  padding: 0;
  border: none;
}


/* button2 styling */
.button2 {
  --border-right: 5px;
  --text-stroke-color: #c79802;
  --animation-color: #c79802;
  --fs-size: 1em;
  text-decoration: none;
  font-size: var(--fs-size);
  font-family: "Arial";
  position: relative;
  text-transform: uppercase;
  color: transparent;
  -webkit-text-stroke: 1px var(--text-stroke-color);
  outline: none;
}
.ItemsContainer {
  display: flex;
  flex-wrap: wrap;
  /* gap: 0.5rem; */
  width: 100%;
  /* margin: 0rem; */
  /* padding: 2rem; */
  /* padding-top: 0rem; */
  /* padding-bottom: 0rem; */
  /* background-color: red; */
  /* flex-direction: column; */
  justify-content: center;
  margin-bottom: 0.5rem;
  align-items: flex-start;
}
