.image-container {
    position: relative;
    display: inline-block; /* Ensures the container wraps its content */
  }
  
  .background-image {
    width: 100%; /* Make sure the image covers the container */
    height: auto; /* Maintain the image's aspect ratio */
  }
  
  .overlay-text {
    position: absolute;
    top: 50%; /* Adjust the top position as needed */
    left: 50%; /* Adjust the left position as needed */
    transform: translate(-50%, -50%); /* Center the button within the container */
    background: transparent; /* Make the button background transparent */
    border: none; /* Remove button border */
    cursor: pointer; /* Change the cursor to a pointer on hover */
    /* Additional button styles can be added as desired */
  }


.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 3;
}
.item {
  position: fixed;
  bottom: 0;
  bottom: -1000px; /* Initial position outside the screen */
  width: 100%;
  height: 70%;
  background-color: #ffffff;
  transition: bottom 0.5s ease-in-out;
  z-index: 4;
  border-top-right-radius: 30px;
  border-top-left-radius: 30px;
}

.item.open {
  bottom: 0; /* Display the sidebar */
}