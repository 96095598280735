.OrderDetailsContainer {
    flex-direction: column;
    align-items: center;
    /* background-color: #fafafa; */
    min-height: 100%;
    display: flex;
    max-width: 100%;
    overflow: auto; /* Enable vertical and/or horizontal scrolling */
    max-height: 58vh; /* Set a maximum height to limit the scrollable area */
}

.OrderImageContainer {
    flex: 0.5 ;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    min-width: 100%;
    margin-bottom: 2rem;
  }
  
  .OrderImage {
    width: 18rem;
    height:18rem;
    object-fit: contain;
  }
  
  .OrderInfo {
    padding-left:.5rem;
    padding-right: .5rem;;
    text-align: start;
  }

  .CounterContainer {
    display: flex;
    align-items: center;
  }
  
  .CounterValue {
    margin: 0 0.5rem;
    font-size: large;
  }
  .InfoPrice{
    max-width: 91%;
    min-width: 91%;
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }
  .Price{
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    font-size: larger;
    font-weight: 500;
  }
 
  .fixedContainer2 {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: white;
    display: flex;
    height: 12vh;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.28);
  }
  
  .BlueContainer {
    background: linear-gradient(270deg, #AF8E4B 0%, #D2B56F 100%);
    border-radius: 5px;
    color: white;
    cursor: pointer;
    width: 80%;
    height: 8vh;
    display: flex; 
    justify-content: center;
    align-items: center;
    padding: 0 1rem; 
  }
  .BlueContainer:hover{
    transform: scale(1.01);
    transition: transform 0.3s, border-width 0.3s;
  }
  
  .AddToBasketButton,
  .PriceTypography { 
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .PriceTypography {
    font-weight: bold;
    font-size: 1.2rem;
  }

  .DisabledContainer {
    background: grey;
    cursor: not-allowed;
  }
  .Notes{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
    width: 90%;
    min-height: 40vh;
    margin-bottom: 8rem;
  }
  .line {
    border-top: 2px solid #DADADA; /* Adjust the style and color as needed */
    margin: 5px 0; /* Adjust the margin for spacing above and below the line */
    width: 90%;
  }