.card2 {
    width: 90vw;
    margin-bottom: 2rem;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
   }
   
.ChoiceInfo{
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
}   
   